import { Injectable } from '@angular/core';
import { BulkPrice, BulkPriceTypeEnum, ProjectDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class BulkPriceService {
    constructor() {}

    getMinPrice(bulkPrices: BulkPrice[], basePrice: number): number {
        return Math.min(...bulkPrices?.map((bulkPrice: BulkPrice) => bulkPrice.price) ?? [], basePrice);
    }

    getMaxPrice(bulkPrices: BulkPrice[], basePrice: number): number {
        return Math.max(...bulkPrices?.map((bulkPrice: BulkPrice) => bulkPrice.price) ?? [], basePrice);
    }

    getPriceRange(bulkPrices: BulkPrice[], basePrice: number): string {
        return `${this.getMinPrice(bulkPrices, basePrice)} &mdash; ${this.getMaxPrice(bulkPrices, basePrice)}`
    }

    getPriceRangeFromProject(project: ProjectDto): string {
        return `${this.getMinPrice(project.bulkPrice, project.pricePerItem)} &mdash; ${this.getMaxPrice(project.bulkPrice, project.pricePerItem)}`
    }

    getPricePerItem(project: ProjectDto, amount: number): number {
        const bulkPrice: BulkPrice[] = project.bulkPrice;

        if (project.bulkPriceType == BulkPriceTypeEnum.Progressive) {
            let price: number = 0;

            for (let i: number = 0; i < bulkPrice.length; i++) {
                const pricePerItem: number = i == 0 ? project.pricePerItem : bulkPrice[i - 1].price

                if (amount >= bulkPrice[i].startAmount){
                    const amountStep: number = bulkPrice[i].startAmount - (i == 0 ? 1 : bulkPrice[i - 1].startAmount);

                    price += pricePerItem * amountStep

                    if (i == bulkPrice.length - 1) {
                        price += bulkPrice[i].price * (1 + amount - bulkPrice[i].startAmount);
                    }

                } else {
                    price += pricePerItem * (1 + amount - (i == 0 ? 1 : bulkPrice[i - 1].startAmount));
                    break;
                }
            }

            return price / amount;
        } else if (project.bulkPriceType == BulkPriceTypeEnum.Constant) {
            for (let i: number = bulkPrice.length - 1; i >= 0; i--) { 
                if (amount >= bulkPrice[i].startAmount){
                    return bulkPrice[i].price;
                }
            }
        }

        return project.pricePerItem;
    }
}