<div class="timeline">
    <div class="timeline-item" *ngFor="let item of data">
        <div class="timeline-badge">
            <div class="timeline-icon">
                <i class="fa fa-3x" [class]="item.icon"></i>
            </div>
        </div>
        <div class="timeline-body">
            <div class="timeline-arrow"></div>
            <div class="timeline-body-container">
                <div class="timeline-body-head-caption">
                    <span class="timeline-head-title"><ng-container *ngTemplateOutlet="title; context: {$implicit: item}"></ng-container>
                      <ng-container *ngIf="!title">{{ item.caption }}</ng-container>
                    </span>
                    <span class="timeline-body-time"><ng-container *ngTemplateOutlet="time; context: {$implicit: item}"></ng-container>
                      <ng-container *ngIf="!time">{{ item.time | luxonFormat: 'F' }}</ng-container>
                    </span>
                </div>
                <div class="timeline-body-content">
                    <ng-container *ngTemplateOutlet="content; context: {$implicit: item}"></ng-container>
                    <ng-container *ngIf="!content">{{ item.content }}</ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
