<div
    appBsModal
    #acceptPrivacyAgreementsModal="bs-modal"
    class="modal fade"
    role="dialog"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="form" (ngSubmit)="accept()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{ l(neverAccepted ? 'PrivacyAgreementsNeverAcceptedHeader' : 'PrivacyAgreementsChangedHeader') }}</span>
                    </h4>
                    <button type="button" class="btn-close" (click)="close()" aria-label="Close">
                        
                    </button>
                </div>
                <div class="modal-body">
                    <span>
                        <b>{{ l(neverAccepted ? 'PrivacyAgreementsNeverAcceptedInfo' : 'PrivacyAgreementsChangedInfo') }}</b>
                    </span>
                    <br/>
                    <br/>
                    <div class="form-group justify-content-start" [ngClass]="{'form-invalid': submitted && (form.invalid || !downloaded)}">
                        <div class="m-checkbox-list">
                            <input
                                id="AcceptPrivacyPolicy"
                                formControlName="acceptPrivacyPolicy"
                                type="checkbox"
                            />
                                
                            <span>
                                {{ l('AcceptPrivacyPolicyPreLink') }}
                                <a class="pointer" (click)="downloadConditions()">{{ l('AcceptPrivacyPolicyLinkText') }}</a>
                                {{ l('AcceptPrivacyPolicyPostLink') }}
                                <a (click)="downloadConditions()" class="download"><i class="fa-solid fa-download text-primary"></i></a>
                            </span>
                            
                            <div *ngIf="(form.invalid || !downloaded) && submitted">
                                <br/>
                                <div *ngIf="form.invalid">{{ l('PrivacyAgreementsChangedRequired') }}</div>
                                <div *ngIf="!downloaded">{{ l('PrivacyAgreementsChangedDownload') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-end">
                    <button type="button" 
                        class="btn btn-light-primary"
                        (click)="close()">
                        {{ l('Cancel') }}
                    </button>
                    <button type="submit" 
                        class="btn btn-primary">
                        <i class="fa-solid fa-badge-check"></i>
                        {{ l('Accept') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div> 