import { AfterViewInit, Directive, ElementRef, Injector, Input } from '@angular/core';

@Directive({
    selector: '[overflowingTextTooltip]',
})
export class OverflowingTextTooltipDirective implements AfterViewInit {
    hostElement: ElementRef;
    @Input() overflowingTextTooltip;

    constructor(injector: Injector, private _element: ElementRef) {
        this.hostElement = _element;
    }

    ngAfterViewInit(): void {
        if (this.overflowingTextTooltip) {
            setTimeout(() => {
                this.hostElement.nativeElement.title = this.getTooltipTextForOverflowingElement();
            });
        }
    }

    getTooltipTextForOverflowingElement() {
        return (this.hostElement.nativeElement.offsetWidth < this.hostElement.nativeElement.scrollWidth) ? this.overflowingTextTooltip : "";
    }
}
