import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableFilterComponent } from './table-filter.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
    imports: [CommonModule, FormsModule, OverlayPanelModule, TooltipModule],
    declarations: [TableFilterComponent],
    exports: [TableFilterComponent],
})
export class TableFilterModule {}
