import { Component, Input, Output, Injector, OnDestroy, AfterContentInit, EventEmitter, TemplateRef, HostListener, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from "rxjs";
import { debounceTime, tap } from 'rxjs/operators';

@Component({
    selector: 'table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['table-filter.component.less']
})
export class TableFilterComponent extends AppComponentBase implements OnDestroy, AfterContentInit {
    @Output() onFilterTextChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFilterChanged: EventEmitter<void> = new EventEmitter<void>();
    @Output() onFilterClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() resetAdvancedFilters: EventEmitter<void> = new EventEmitter();
    @Input() filterPanel: TemplateRef<HTMLElement>;
    @Input() selectionActions: TemplateRef<HTMLElement>;
    @Input() totalRecords: number;
    @Input() unfilteredTotal: number;
    @Input() selectedDataCount: number;
    @Input() enableOverlay: boolean = false;
    @Input() searchTooltip: string;
    @Input() disableFilterButton: boolean = false;
    @Input() canResetAdvancedFilter: boolean = false;

    @ViewChild('filterOverlayPanel', { static: true }) filterOverlayPanel: OverlayPanel

    @HostListener('document:keyup', ['$event'])
    handleKeyUpEvent(event: KeyboardEvent) { 
        if (event.key == "Escape") {
            this.filterOverlayPanel.hide();
        }
    }

    filterText: string;
    filterText$: Subject<void> = new Subject();
    filter$: Subject<void> = new Subject();

    constructor(injector: Injector) {
        super(injector);
    }

    get filterTextIsEmpty(): boolean {
        return !this.filterText || this.filterText == "";
    }

    filterTextChanged(value:string) {
        this.filterText = value;
        this.filterText$.next();
    }

    clearTextFilter(): void {
        this.filterTextChanged("");
    }

    filterClicked(event: Event): void {
        if (this.enableOverlay) {
            this.filterOverlayPanel.toggle(event);
        }

        this.onFilterClicked.emit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.filterText$.complete();
        this.filter$.complete();
    }

    ngAfterContentInit(): void {
        this.filterText$
            .pipe(
                debounceTime(500),
                tap(_ => {
                    this.onFilterTextChanged.emit(this.filterText);
                })
            )
            .subscribe();

        this.filter$
            .pipe(
                debounceTime(250),
                tap(_ => {
                    this.onFilterChanged.emit();
                })
            )
            .subscribe();
    }

    debounceFilter(): void {
        this.filter$.next();
    }
}