import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import * as rtlDetect from 'rtl-detect';

export class PrimengTableHelper {
    shiftPressed: boolean = false;
    lastSelectedIndex: number;

    handleKeyDownEvent(event: KeyboardEvent) {
        if (event.key == "Shift"){
            this.shiftPressed = true;
        }
    }

    handleKeyUpEvent(event: KeyboardEvent) { 
        if (event.key == "Shift"){
            this.shiftPressed = false;
        }
    }

    predefinedRecordsCountPerPage = [5, 10, 25, 50, 100, 250, 500];

    defaultRecordsCountPerPage = 10;

    defaultPageLinks: number = 5;

    isResponsive = true;

    resizableColumns: false;

    totalRecordsCount = 0;

    private _records: any[];
    selectedData: any[] = [];

    isLoading = false;

    private _currentPage: number;
    private _rows: number;

    get currentPage(): number{
        return this._currentPage;
    }

    get records(): any[] {
        return this._records;
    }

    set records(newValue: any[]){
        this._records = newValue;
        this.lastSelectedIndex = null;
    }

    get paginator(): Paginator{
        const ret: any = {
            first: this.currentPage * this._rows,
            rows: this._rows
        }

        return ret;
    }

    showLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = true;
        }, 0);
    }

    hideLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = false;
        }, 0);
    }

    updatePaginator(event: LazyLoadEvent): void {
        if (event){
            this._rows = event.rows;
            this._currentPage = event.first / event.rows;
        }
    }

    resetPaging(): void {
        this._currentPage = 0;
    }

    getSorting(table: Table): string {
        let sorting = '';

        if (table.sortMode === 'multiple') {
            if (table.multiSortMeta) {
                for (let i = 0; i < table.multiSortMeta.length; i++) {
                    const element = table.multiSortMeta[i];
                    if (i > 0) {
                        sorting += ',';
                    }
                    sorting += element.field;
                    if (element.order === 1) {
                        sorting += ' ASC';
                    } else if (element.order === -1) {
                        sorting += ' DESC';
                    }
                }
            }
        } else {
            if (table.sortField) {
                sorting = table.sortField;
                if (table.sortOrder === 1) {
                    sorting += ' ASC';
                } else if (table.sortOrder === -1) {
                    sorting += ' DESC';
                }
            }
        }

        return sorting;
    }

    getMaxResultCount(paginator: Paginator, event: LazyLoadEvent): number {
        if (!event) {
            if (paginator?.rows) {
                return paginator.rows;
            }
            return 0;
        }

        return event.rows;
    }

    getSkipCount(paginator: Paginator, event: LazyLoadEvent): number {
        if (!event) {
            if (paginator?.first) {
                return paginator.first;
            }

            return 0;
        }

        return event.first;
    }

    shouldResetPaging(event: LazyLoadEvent): boolean {
        if (!event /*|| event.sortField*/ && (this._records?.length || 0) > 0) {
            // if you want to reset after sorting, comment out parameter
            return true;
        }

        return false;
    }

    adjustScroll(table: Table) {
        const rtl = rtlDetect.isRtlLang(abp.localization.currentLanguage.name);
        if (!rtl) {
            return;
        }

        const body: HTMLElement = table.el.nativeElement.querySelector('.p-datatable-scrollable-body');
        const header: HTMLElement = table.el.nativeElement.querySelector('.p-datatable-scrollable-header');
        body.addEventListener('scroll', () => {
            header.scrollLeft = body.scrollLeft;
        });
    }

    select(index: number, selector: any = (record: any) => record.id): void{
        const pagedIndex: number = index - this.paginator.first;

        const selectedRecord: any = this._records[pagedIndex];

        const isSelected: boolean = this.selectedData.find((value: any) => selector(value) == selector(selectedRecord)) != undefined;

        if (isSelected) {
            if (this.shiftPressed && this.lastSelectedIndex != undefined) {
                for (let i: number = Math.min(pagedIndex, this.lastSelectedIndex); i < Math.max(pagedIndex, this.lastSelectedIndex); i++) {

                    const record: any = this._records[i];    

                    if (this.selectedData.find((value: any) => selector(value) == selector(record)) == undefined){
                        this.selectedData = [...this.selectedData, record]
                    }
                }
            }

            this.lastSelectedIndex = pagedIndex;
        } else {
            this.lastSelectedIndex = undefined;
        }
    }
}
