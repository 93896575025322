import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'asistix-help-button',
  templateUrl: './asistix-help-button.component.html',
  styleUrls: ['./asistix-help-button.component.css']
})
export class AsistixHelpButtonComponent implements OnInit {
  @Input() customStyle = 'btn btn-icon btn-active-primary position-relative w-30px h-25px w-md-40px h-md-40px me-2';
  @Input() iconStyle = 'fas fa-question fa-2x';

  public helpLink:string = "";
  public sosLink:string = "";
  

  ngOnInit(): void {
      this.helpLink = abp.setting.get("App.GeneralCustomization.HelpLink");
      this.sosLink = abp.setting.get("App.GeneralCustomization.SosLink");
  }

}
