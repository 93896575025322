<div class="card card-custom gutter-b mb-10">
    <div class="card-body row">
        <form class="form col-4" autocomplete="off">                        
            <div class="row align-items-center">
                <div class="input-group d-flex">
                    <span class="p-input-icon-right flex-grow-1">
                        <i class="fa-light fa-times" style="cursor: pointer;" (click)="clearTextFilter()" *ngIf="!filterTextIsEmpty"></i>
                        <input
                            [ngModel]="filterText"
                            (ngModelChange)="filterTextChanged($event)"
                            name="filterText"
                            autoFocus
                            class="form-control m-input"
                            [placeholder]="l('SearchWithThreeDot')"
                            [tooltip]="searchTooltip"
                            [tooltipEnable]="searchTooltip != ''"
                            type="text"
                        />
                    </span>

                    <span class="input-group-btn" *ngIf="!disableFilterButton">
                        <button class="btn icononly btn-primary filter-toggle" (click)="filterClicked($event)">
                            <i class="fa-solid fa-filter"></i>
                        </button>
                    </span>

                    <p-overlayPanel 
                        #filterOverlayPanel
                        [dismissable]="true" 
                        [showCloseIcon]="true">
                        <div class="form-group">
                            <ng-template [ngTemplateOutlet]="filterPanel">
                    
                            </ng-template> 
                        </div>
                    </p-overlayPanel>
                </div>
            </div>
        </form>

        <div class="col-8 d-flex">
            <div>
                <div *ngIf="unfilteredTotal == 1">
                    {{ l('TotalRecordsCountSingular', unfilteredTotal) }}
                </div>
                <div *ngIf="unfilteredTotal != 1">
                    {{ l('TotalRecordsCountPlural', unfilteredTotal) }}
                </div>
                <span class="filtered-text">
                    <ng-template [ngIf]="unfilteredTotal == totalRecords">
                        {{ l('NoFiltersApplied') }}
                    </ng-template>
                    <ng-template [ngIf]="unfilteredTotal != totalRecords && totalRecords == 1">
                        {{ l('FilteredRecordsCountSingular', totalRecords) }}
                    </ng-template>
                    <ng-template [ngIf]="unfilteredTotal != totalRecords && totalRecords != 1">
                        {{ l('FilteredRecordsCountPlural', totalRecords) }}
                    </ng-template>
                    <a class="reset-link" (click)="resetAdvancedFilters.emit()" *ngIf="!disableFilterButton && canResetAdvancedFilter">
                        <u class="filtered-text">{{ l('ResetAdvancedFilter') }}</u>
                    </a>
                </span>
            </div>
            <div class="d-flex align-items-center justify-content-end col" *ngIf="selectedDataCount > 0">
                <div style="margin: 10px">
                    <ng-template [ngIf]="selectedDataCount == 1">
                        {{ l('SelectedDatasetsCountSingular') }}
                    </ng-template>
                    <ng-template [ngIf]="selectedDataCount != 1">
                        {{ l('SelectedDatasetsCountPlural', selectedDataCount) }}
                    </ng-template>
                </div>
                <ng-template [ngTemplateOutlet]="selectionActions">
                        
                </ng-template> 
            </div>
        </div>
    </div>
</div>