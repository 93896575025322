import { Injectable } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

class ErrorDef {
    error: string;
    localizationKey: string;
    errorProperty: string;
}

@Injectable()
export class CreatorValidationService
{
    constructor(private loc: AppLocalizationService) {}

    readonly standartErrorDefs: ErrorDef[] = [
        { error: 'required', localizationKey: 'ThisFieldIsRequired' } as ErrorDef,
        {
            error: 'minlength',
            localizationKey: 'PleaseEnterAtLeastNCharacter',
            errorProperty: 'requiredLength',
        } as ErrorDef,
        {
            error: 'maxlength',
            localizationKey: 'PleaseEnterNoMoreThanNCharacter',
            errorProperty: 'requiredLength',
        } as ErrorDef,
        { error: 'email', localizationKey: 'InvalidEmailAddress' } as ErrorDef,
        { error: 'unique', localizationKey: 'EntryMustBeUnique' } as ErrorDef,
        { error: 'pattern', localizationKey: 'InvalidPattern', errorProperty: 'requiredPattern' } as ErrorDef,
    ];

    get errorDefs(): ErrorDef[] {
        return this.standartErrorDefs;
    }

    getValidation(formCtrl: any) {
        if (!formCtrl.invalid) return "";

        var ret = [];
        const ed = this.errorDefs;
        for(var i = 0; i < ed.length; i++) {
            if (!!formCtrl.errors[ed[i].error]) {
                let errorRequirement = formCtrl.errors[ed[i].error][ed[i].errorProperty];
                ret.push(!!errorRequirement
                    ? this.loc.l(ed[i].localizationKey, errorRequirement)
                    : this.loc.l(ed[i].localizationKey));
            }
        }

        return ret.join(", ");
    }
}