import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { stringify } from "querystring";

@Directive({
  selector: '[forbiddennames]',
  providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenNameDirective, multi: true}]
})
export class ForbiddenNameDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    
    var inputCompare = <string>control.value??'';
    const fChecker = {'forbiddenNameError': true};
    if(inputCompare.toLowerCase()=="id" || inputCompare.toLowerCase()=="dataid" ||inputCompare.toLowerCase()=="datavalid"){
      return fChecker;
    }
    else {
      return null;
    }
  }
}