import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConditionsAndPrivacyAgreementsTypeEnum, ConditionsServiceProxy, FileDto, PrivacyAgreementAcceptedEnum } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'accept-privacy-agreement',
    templateUrl: './accept-privacy-agreement-modal.component.html',
    styleUrls: ['./accept-privacy-agreement-modal.component.less']
})
export class AcceptPrivacyAgreementModalComponent extends AppComponentBase implements OnInit{
    @ViewChild('acceptPrivacyAgreementsModal', { static: true }) modal: ModalDirective;

    form: FormGroup;
    submitted: boolean = false;
    downloaded: boolean = false;
    neverAccepted: boolean;

    constructor(injector: Injector,
        private _authService: AppAuthService,
        private _formBuilder: FormBuilder,
        private _fileDownloadService: FileDownloadService,
        private _conditionsServiceProxy: ConditionsServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            acceptPrivacyPolicy: [false, Validators.requiredTrue]
        });
    }

    show(privacyAgreementAccepted: PrivacyAgreementAcceptedEnum): void {
        this.neverAccepted = privacyAgreementAccepted == PrivacyAgreementAcceptedEnum.NeverAccepted;
        this.modal.show();
    }

    close(): void {
        this.message.confirm(this.l("PrivacyAgreementsChangedLogout"), this.l("Confirmation"), (result: boolean) => {
            if (!result) {
                return; 
            }

            this.modal.hide();
            this._authService.logout();
        });
    }

    downloadConditions(): void {
        this.downloaded = true;
        this._conditionsServiceProxy.downloadCurrent(ConditionsAndPrivacyAgreementsTypeEnum.PrivacyAgreement).subscribe((result: FileDto) => {
            result.isInline = true;
            this._fileDownloadService.downloadTempFile(result);
          });
    }

    accept(): void {
        this.submitted = true;

        if (this.form.valid && this.downloaded) {
            this._conditionsServiceProxy.acceptCurrentPrivacyAgreement().subscribe(() => {
                this.message.info(this.l("PrivacyAgreementsChangedAccepted"));

                this.modal.hide();
            });
        }
    }
}