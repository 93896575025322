<div style="width: 100%">
<div class="d-flex">
  <div class="image" *ngFor="let project of projects" [routerLink]="['/app/main/creatorProject/projects/details']" [queryParams]="{ id: project.id }">
    <img [src]="imageBaseUri + 'front/' + project.id" class="img-viewproduct" *ngIf="project.hasPreviewImageFront" />
    <preview-placeholder
        *ngIf="!project.hasPreviewImageFront" 
        className="img-viewproduct"
    ></preview-placeholder>
    <br>
    <small>{{ project.name }}</small>
  </div>
</div>
</div>