import { Component, Injector, NgZone, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";
import { OrdersServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    templateUrl: './shoppingcart.component.html',
    selector: 'shoppingcart',
    styleUrls: ['./shoppingcart.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class ShoppingcartComponent extends AppComponentBase implements OnInit {

    public items: number = 0;

    constructor(
        injector: Injector,
        public _zone: NgZone,
        private orders: OrdersServiceProxy,
        private _router: Router
    ) {
        super(injector);
    }

    ngOnInit(): void {
        const self = this;
        this.subscribeToEvent("creator.updatecart", () => {
            self._zone.run(() => {
                self.updateEntryCount();
            });
        });

        self.updateEntryCount();
    }

    updateEntryCount() {
        this.orders.getCurrentOrderCount().subscribe(res => {
            if (res) {
                this.items = res;
            }
            else {
                this.items = 0;
            }
        })
    }
    
    openOrder() {
        this.orders.getCurrentOrder('').subscribe(res => {
            if (!res.order) {
                this.message.error(this.l("CurrentOrderUnavailable"));
                return;
            }

            this._router.navigate(['app/main/shop/orders/createOrEdit'], { queryParams: {id: res.order.id}});
        })
    }
}