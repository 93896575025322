import { Directive, Input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { CustomersServiceProxy } from "@shared/service-proxies/service-proxies";
import { Observable, pipe, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Directive({
  selector: '[uniqueCustomer]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueCustomerIdValidatorDirective, multi: true}]
})
export class UniqueCustomerIdValidatorDirective implements AsyncValidator {
  @Input('uniqueCustomer') ownId! : string | null;

  constructor(private customerProxy: CustomersServiceProxy) {}

  validate(control: AbstractControl): Observable<ValidationErrors|null> {
    const debounceTimer = 400;
    return timer(debounceTimer).pipe(
      switchMap(() => this.customerProxy.validateCustomerNumber(control?.value ?? '', this.ownId)),
      map(
      result => {
        console.log("Result for Validation of ", control?.value, " is ", result);
        return result ? null : { "duplicateCustomerId": true };
      })
    );
  }
}