<div class="d-flex align-items-center dropdown-help" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
    <div [class]="customStyle">
        <i [class]="iconStyle"></i>
    </div>
</div>
<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-primary fw-bold fs-7 w-300px py-4"
     data-kt-menu="true">
     <div class="menu-item px-3">
        <a [href]="helpLink" target="_blank" class="menu-link px-3">
            <span class="d-flex symbol symbol-20px me-4">
                <i class="fal fa-book"></i>
            </span>
            {{ 'InstructionManualLink' | localize }}
        </a>
    </div>
     <div class="menu-item px-3" *ngIf="!!sosLink">
        <a [href]="sosLink" target="_blank" class="menu-link px-3">
            <span class="d-flex symbol symbol-20px me-4">
                <i class="fal fa-life-ring"></i>
            </span>
            {{ 'HelpSoftwareLink' | localize }}
        </a>
    </div>
</div>