import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'defaulttext'
})
export class DefaultTextPipe implements PipeTransform {

    constructor() {
    }

    transform(key: string, def: string): string {
        return (key)?key:def;
    }
}