import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GetProjectForViewDto, ProjectDto, ProjectsServiceProxy } from '@shared/service-proxies/service-proxies';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'visual-product-list',
  templateUrl: './visual-product-list.component.html',
  styleUrls: ['./visual-product-list.component.less']
})
export class VisualProductListComponent extends AppComponentBase implements OnChanges, OnDestroy {
  @Input() customerId?: string;
  @Input() mode: 'id' | 'data' = 'id';
  @Input() projects! : ProjectDto[];
  imageBaseUri : string = AppConsts.remoteServiceBaseUrl + "/projectpreview/";
  
  constructor(injector: Injector, private proxy: ProjectsServiceProxy) {
      super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.mode == 'id') {
      this.proxy.getAllByCustomer(this.customerId).subscribe(res => {
        this.projects = res.map(r => r.project);
      });
    }
  }
}