import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[compareNumber][formControlName],[compareNumber][formControl],[compareNumber][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => CompareNumberValidator), multi: true }],
})
export class CompareNumberValidator implements Validator {
    constructor(
        @Attribute('compareNumber') public compareNumber: string,
        @Attribute('compareNumberType') public compareNumberType: 'gt' | 'lt' | 'gte' | 'lte'
    ) {}

    validate(control: AbstractControl): ValidationErrors | null {
      const inputSelf = <number>control.value ?? 0;
      const inputOther = <number>control.root.get(this.compareNumber)?.value ?? 0

      var list = [];
      const controls = (control as FormGroup).controls
      for (const name in controls) {
         list.push(name);
      }

      console.log("Validating CompareNumber ", inputSelf, inputOther, list);

      if (this.compareNumberType == 'gt' && inputSelf > inputOther) return null;
      if (this.compareNumberType == 'gte' && inputSelf >= inputOther) return null;
      if (this.compareNumberType == 'lt' && inputSelf < inputOther ) return null;
      if (this.compareNumberType == 'lte' && inputSelf <= inputOther) return null;
      return { compareError: true };
  }
}