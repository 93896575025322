import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from "@angular/core";
import { PrimeTemplate } from "primeng/api";

export interface ITimelineItem {
  caption: string;
  time: any;
  content: any;
  icon: string;
}
@Component({
  selector: 'asistix-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.less']
})
export class TimelineComponent implements AfterContentInit {

  title : TemplateRef<any> | null;
  time : TemplateRef<any> | null;
  content : TemplateRef<any> | null;
  @Input() data : ITimelineItem[];
  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate> | undefined;

  constructor() { }

  ngAfterContentInit(): void {
    this.title = this.templates?.find(t => t.name == "title")?.template;
    this.time = this.templates?.find(t => t.name == "time")?.template;
    this.content = this.templates?.find(t => t.name == "content")?.template;
  }
}
