import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[unique]',
  providers: [{provide: NG_VALIDATORS, useExisting: UniqueValidatorDirective, multi: true}]
})
export class UniqueValidatorDirective implements Validator {
  @Input('unique') namePattern = '';

  validate(control: AbstractControl): ValidationErrors | null {
    var pat = new RegExp(this.namePattern);
    const group = control.parent.controls;
    const keys = Object.keys(group);
    var toCompare = keys.filter(n => pat.test(n));
    const val = control.value;
    const ownname = keys.find(name => group[name] === control);
    for(var i = 0; i < toCompare.length; i++) {
      if (val == control.parent.get(toCompare[i]).value && ownname != toCompare[i]) {
        return {unique: {value: val}};
      }
    }
    return null;
  }
}